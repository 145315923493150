import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="base">
          <dl className="block-table03">
            <div className="wrp-img">
              <Link to="/" onClick={toTop}>
                <img
                  src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/logo-bl.png"
                  alt=""
                />
              </Link>
            </div>
            <div className="wrp-navi">
              <ul className="wrp-txt">
                <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-29">
                  <Link to="/" aria-current="page" onClick={toTop}>
                    HOME
                  </Link>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-30">
                  <Link to="/contact" onClick={toTop}>
                    お問い合わせ
                  </Link>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-642">
                  <Link to="/particular" onClick={toTop}>
                    特定商取引法の表記
                  </Link>
                </li>
              </ul>
              <ul className="sns"></ul>
            </div>
          </dl>
        </div>
        <section className="block-copy01">
          <ul className="nav">
            <li>
              <Link to="/privacy" onClick={toTop}>
                プライバシーポリシー
              </Link>
            </li>
          </ul>
          <div className="copy">
            <img
              width="12.5"
              height="13"
              src="https://img.icons8.com/material-outlined/24/FFFFFF/creative-commons-all-rights-reserved.png"
              alt="creative-commons-all-rights-reserved"
            />
            <font _mstmutation="1"> Interior Coordinate 74</font>
          </div>
        </section>
      </div>
    </>
  );
}

export default Footer;
