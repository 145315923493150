import React, { useState, useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import "../css/mainFirst.css";
import productData from "../Datas/productData";
import { CartContext } from "../Component/CartContext";
import { Link } from "react-router-dom";

function Main() {
  const [openIndex, setOpenIndex] = useState(null);
  const backgroundImage =
    "url(https://interiorcoordinate74.jp/wp-content/uploads/2023/09/リビング　照明-1.jpg)";
  const faqs = [
    {
      question: "提案するアイテムの品質は大丈夫でしょうか。",
      answer: "家具のプロですのでご信頼いただけたらと思います...",
    },
    {
      question: "ご提案頂いたアイテムは必ず買わないといけないのでしょうか。",
      answer: "いえ、ご購入頂いてもよいですし...",
    },
    {
      question: "建物が完成していないのですがコーディネートしてもらえますか。",
      answer: "はい。お部屋の写真がなくても...",
    },
    {
      question: "近くの実店舗で展示されている家具も提案してもらえますか。",
      answer: "ウェブサイトがあるお近くの店舗の取扱商品から...",
    },
    {
      question: "高価格帯のコーディネートはできますか。",
      answer: "はい。国内外のハイブランドのアイテムを組み合わせた...",
    },
  ];
  const blogPosts = [
    {
      imgSrc:
        "https://interiorcoordinate74.jp/wp-content/uploads/2024/07/【System-Auto-save】Untitled_【System-Auto-save】Untitled-11.jpeg",
      title: "インテリア3Dパース養成講座について",
      link: "https://interiorcoordinate74.jp/blog/1600/",
      subtit: "#パース #養成講座",
      description:
        "この度、オンラインインテリアコーディネート74ではインテリア3Dパースを作れるようになりたいという方向けの養成講座を始め…",
    },
    {
      imgSrc:
        "https://interiorcoordinate74.jp/wp-content/uploads/2024/05/スクリーンショット-2024-05-04-064947.png",
      title: "建売・不動産会社向け家具家電コーディネートサービス",
      link: "https://interiorcoordinate74.jp/blog/1388/",
      subtit: "#不動産仲介会社 #家具家電",
      description:
        "この度オンラインインテリアコーディネート74では建売・不動産仲介会社様とタイアップして、建売住宅、アパートやマンション等…",
    },
    {
      imgSrc:
        "https://interiorcoordinate74.jp/wp-content/uploads/2024/09/IMG_2811-scaled.jpg",
      title: "関西・大阪のインテリアコーディネートの依頼ができる（？）お店3選",
      link: "https://interiorcoordinate74.jp/blog/1823/",
      subtit: "#インテリアコーディネート #大阪",
      description:
        "今回は関西・大阪におけるインテリアコーディネートの依頼ができる（？も含む）インテリアショップを3店舗紹介したいと思います…",
    },
  ];
  const { toCheck } = useContext(CartContext);
  const toCheckOut = (id, name, price) => {
    toCheck(id, name, price);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="transparent-div">
          <img alt="" src="/head/main.jpg" />
          <div className="block-mv04_txt">
            インテリアコーディネートを低価格で誰でも。
          </div>
        </div>
        <div className="mainBox1" style={{ padding: "7% 13% 0 13%" }}>
          <div className="base-sub">
            <div className="wrp-box">
              <div className="txt-box">
                <h2 className="tit maskimg mask-left isPlay-left">
                  インテリアコーディネートを
                  <br />
                  低価格で誰でも。
                </h2>
                <p className="txt maskimg mask-left isPlay-left">
                  オンラインインテリアコーディネート74はLINEやメールでインテリアコーディネートを依頼できるサービスです。
                  <br />
                  コーディネートでご提案する家具はイケア、ニトリ、楽天、Amazonなど国内全てのウェブサイトのアイテムが対象となります。
                </p>
              </div>
              <div className="img-box fadein fadein-bottom isPlay">
                <img
                  src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/about-top.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="base">
            <div className="com-tit">
              <h2 className="tit">
                about<span>74のメリット</span>
              </h2>
            </div>
            <ul className="block-list-box05">
              <li className="fadein fadein-bottom isPlay">
                <div className="wrp-img">
                  <img
                    src="https://interiorcoordinate74.jp/wp-content/uploads/2023/05/gurahu-3man3.png"
                    alt=""
                    className=""
                  />
                </div>
                <h3 className="tit"># 低価格</h3>
                <p className="txt">
                  価格を抑えたコーディネートができ、
                  <span>リビング家具一式で10万円以下にすることも可能。</span>
                  <br />
                  コーディネート料金が発生しても家具の金額が下がると合計金額も抑えられる。
                </p>
              </li>
              <li className="fadein fadein-bottom isPlay">
                <div className="wrp-img">
                  <img
                    src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/about-thum02.png"
                    alt=""
                    className=""
                  />
                </div>
                <h3 className="tit"># 理想の空間づくり</h3>
                <p className="txt">
                  限られたブランドを扱っている家具販売店とは違い国内全てのウェブサイト(海外ブランド含む)のアイテムが対象のため、
                  <span>
                    理想的なサイズ・デザインの家具でコーディネートができる。
                  </span>
                </p>
              </li>
              <li className="fadein fadein-bottom isPlay">
                <div className="wrp-img">
                  <img
                    src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/about-thum03.png"
                    alt=""
                    className=""
                  />
                </div>
                <h3 className="tit"># 安心</h3>
                <p className="txt">
                  建築、インテリアの専門知識があり、品質的に信頼できるアイテムしかご提案しないので
                  <span>家具選びに失敗しない。</span>
                </p>
              </li>
              <li className="fadein fadein-bottom isPlay">
                <div className="wrp-img">
                  <img
                    src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/about-thum04.png"
                    alt=""
                    className=""
                  />
                </div>
                <h3 className="tit"># 丁寧</h3>
                <p className="txt">
                  メールやLINEのやりとりだけでプロのコーディネートを誰もが受けられる。やりとりは早朝・深夜もOK。
                  <span>満足できるまで丁寧にご対応。</span>
                </p>
              </li>
            </ul>
          </div>
          <div className="base-flow">
            <div className="com-tit">
              <h2 className="tit">
                flow<span>サービスの流れ</span>
              </h2>
            </div>
            <dl className="wrp-flow04">
              <div className="box fadein fadein-bottom isPlay">
                <div className="num">
                  STEP<span>01</span>
                </div>
                <div className="icon">
                  <img
                    src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/flow-icon01.png"
                    alt=""
                  />
                </div>
                <div className="txt">
                  <dt>
                    <span className="icon-tit">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/flow-icon01.png"
                        alt=""
                      />
                    </span>
                    お申し込み
                  </dt>
                  <dd>
                    <p>ご依頼フォームまたはLINE公式アカウントよりお申し込み</p>
                  </dd>
                </div>
              </div>
              <div className="box fadein fadein-bottom isPlay">
                <div className="num">
                  STEP<span>02</span>
                </div>
                <div className="icon">
                  <img
                    src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/flow-icon02.png"
                    alt=""
                  />
                </div>
                <div className="txt">
                  <dt>
                    <span className="icon-tit">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/flow-icon02.png"
                        alt=""
                      />
                    </span>
                    ヒアリングシート記入
                  </dt>
                  <dd>
                    <p>
                      ヒアリングシートをお送りしますので質問にメール・LINEでご回答ください
                    </p>
                  </dd>
                </div>
              </div>
              <div className="box fadein fadein-bottom isPlay">
                <div className="num">
                  STEP<span>03</span>
                </div>
                <div className="icon">
                  <img
                    src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/flow-icon03.png"
                    alt=""
                  />
                </div>
                <div className="txt">
                  <dt>
                    <span className="icon-tit">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/flow-icon03.png"
                        alt=""
                      />
                    </span>
                    お見積り、お支払い
                  </dt>
                  <dd>
                    <p>
                      ヒアリングシートの内容をもとにコーディネート料を算定、お支払(カード・振込)頂きましたらコーディネートをスタート。
                      <br />
                      もう少し詳しくヒアリングさせて頂きます。（コーディネート料金については料金リスト参照）
                    </p>
                  </dd>
                </div>
              </div>
              <div className="box fadein fadein-bottom isPlay">
                <div className="num">
                  STEP<span>04</span>
                </div>
                <div className="icon">
                  <img
                    src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/flow-icon04.png"
                    alt=""
                  />
                </div>
                <div className="txt">
                  <dt>
                    <span className="icon-tit">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/flow-icon04.png"
                        alt=""
                      />
                    </span>
                    コーディネート案を提出
                  </dt>
                  <dd>
                    <p>
                      ヒアリング内容をもとに作成したコーディネート案をご提出
                      <br />
                      <span className="inline">
                        ※お申込みからコーディネート案の提出までに要する日数は
                        <span className="bold">右上のご依頼ボタン</span>
                        より確認することができます。
                      </span>
                    </p>
                  </dd>
                </div>
              </div>
              <div className="box fadein fadein-bottom isPlay">
                <div className="num">
                  STEP<span>05</span>
                </div>
                <div className="icon">
                  <img
                    src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/flow-icon05.png"
                    alt=""
                  />
                </div>
                <div className="txt">
                  <dt>
                    <span className="icon-tit">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/flow-icon05.png"
                        alt=""
                      />
                    </span>
                    ご質問、修正
                  </dt>
                  <dd>
                    <p>
                      コーディネート案について質問や修正点があればおっしゃってください。
                      <br />
                      ご満足頂けるまでご対応させていただきます。
                    </p>
                  </dd>
                </div>
              </div>
              <div className="box fadein fadein-bottom isPlay">
                <div className="num">
                  STEP<span>06</span>
                </div>
                <div className="icon">
                  <img
                    src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/flow-icon06.png"
                    alt=""
                  />
                </div>
                <div className="txt">
                  <dt>
                    <span className="icon-tit">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/flow-icon06.png"
                        alt=""
                      />
                    </span>
                    納品・完成
                  </dt>
                  <dd>
                    <p>
                      商品をご注文いただくとご自宅に商品が納品されお部屋が完成♫
                    </p>
                  </dd>
                </div>
              </div>
            </dl>
          </div>
          <div className="base">
            <div className="com-tit">
              <h2 className="tit">
                <font _mstmutation="1">price</font>
                <span>料金リスト</span>
              </h2>
            </div>
            <div className="headline">
              <span className="en">#Private</span>
              <span className="ja">個人</span>
            </div>
            <div className="wrp-pricebox">
              {productData
                .filter((e) => e.id >= 1 && e.id <= 2)
                .map((room, index) => (
                  <div className="price-box" key={index}>
                    <div className="head-line-wrap">
                      <div className="head-line-container">
                        <h3 className="head-line maskimg mask-left isPlay-left">
                          <font _mstmutation="1">
                            <span className="tit" _mstmutation="1">
                              　{room.name}
                            </span>
                            <span className="price" _mstmutation="1">
                              ￥{room.price}-
                            </span>
                          </font>
                        </h3>
                      </div>
                    </div>
                    <p className="txt"></p>
                    <div className="wrp-list2">
                      <ul className="list">
                        {room.details.map((detail, index) => (
                          <li key={index}>{detail}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="block-btn03" style={{ marginBottom: "5%" }}>
                      <Link
                        to={room.linkPdf}
                        rel="noopener noreferrer"
                        className="btn"
                      >
                        <font _mstmutation="1">実際の提案サンプルを見る</font>
                        <span className="arrow"></span>
                      </Link>
                    </div>
                    <div className="block-btn03">
                      <Link
                        to="/checkout"
                        className="btn"
                        onClick={() =>
                          toCheckOut(room.id, room.name, room.price)
                        }
                      >
                        購入
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
            <div className="wrp-list">
              <h3 className="sub-head">【オプション】</h3>
              <ul className="block-list-box07">
                <li>
                  <div className="wrp-container">
                    <div className="wrp-img">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/uploads/2024/04/スクリーンショット-2024-04-18-002645.png"
                        alt=""
                        className=""
                      />
                    </div>
                    <p className="wrp-txt">
                      <span className="tit">
                        +もう1部屋インテリアコーディネートしてほしい
                      </span>
                      <span className="txt">
                        <font _mstmutation="1">
                          ＋¥22,000(1部屋につき。)
                          <span className="num" _mstmutation="1">
                            ※1
                          </span>
                        </font>
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="wrp-container">
                    <div className="wrp-img">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/option-icon03.png"
                        alt=""
                        className=""
                      />
                    </div>
                    <p className="wrp-txt">
                      <span className="tit">リフォームの提案もしてほしい</span>
                      <span className="txt">
                        <font _mstmutation="1">
                          ＋¥11,000(1部屋につき。)
                          <span className="num" _mstmutation="1">
                            ※2
                          </span>
                        </font>
                      </span>
                    </p>
                  </div>
                </li>
              </ul>
              <div className="add">(全て税込)</div>
            </div>
            <div className="headline">
              <span className="en">#Corp</span>
              <span className="ja">法人</span>
            </div>
            <div className="txt-box">
              <div className="txt fadein fadein-bottom isPlay">
                <p>
                  店舗・施設・オフィス・パースのみ等法人様のインテリアコーディネートは多種多様なご依頼があります。
                  <br />
                  料金に関しては上記料金をベースにご依頼・お問い合わせ後に別途お見積りさせて頂きます。
                  <br />
                  法人様に関しては請求書での後払い、商品手配のご相談も承っております。
                  <br />
                  <br />
                  その他、建売・不動産仲介会社様の新規ご契約者向けコーディネートサービスもしています。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="ph-box">
          <img
            src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/top-img01.jpg"
            alt=""
            className="fadein fadein-bottom isPlay"
          />
          <img
            src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/top-img02.jpg"
            alt=""
            className="fadein fadein-bottom isPlay"
          />
        </div>
        <div className="wrp-addlist">
          <ul className="add-list">
            <li>
              <p>
                ※1　リビングと繋がっている洋室のような間取り等、繋がっていても扉や引戸等で区切られる場合はもう1部屋の対象となるのでご注意ください。
              </p>
            </li>
            <li>
              <p>※2　リフォーム工事の施工業者の紹介も可能です。</p>
            </li>
          </ul>
        </div>

        <section className="block-category">
          <div className="base">
            <div className="com-tit">
              <h2 className="tit">
                <font _mstmutation="1">category</font>
                <span>インテリアコーディネートする建物について</span>
              </h2>
            </div>
            <div className="txt-box">
              <p>
                インテリアコーディネート74では戸建住宅はもちろん、
                <br />
                マンションやアパート、一人暮らしのワンルームから法人向けの店舗・事務所・
                <br />
                施設のインテリアコーディネートまで幅広く対応しています。
              </p>
            </div>
            <ul className="block-ph-ontxt04">
              <li className="bgimg link-box fadein fadein-bottom isPlay">
                <Link to="/" className="tit">
                  <span className="num">01</span>
                  <span className="txt">戸建住宅</span>
                </Link>
              </li>
              <li
                className="bgimg link-box fadein fadein-bottom isPlay"
                style={{
                  backgroundImage:
                    'url("https://interiorcoordinate74.jp/wp-content/uploads/2022/07/cate-thum02.jpg")',
                  cursor: "pointer",
                }}
              >
                <Link to="/" className="tit">
                  <span className="num">02</span>
                  <span className="txt">
                    マンション・アパート
                    <br />
                    一人暮らしワンルーム
                  </span>
                </Link>
              </li>
              <li className="bgimg link-box fadein fadein-bottom isPlay">
                <Link to="/" className="tit">
                  <span className="num">03</span>
                  <span className="txt">
                    店舗・事務所・施設
                    <br />
                    （法人）
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </section>

        <section className="block-faq">
          <div className="base">
            <div className="com-tit">
              <h2 className="tit">
                <font _mstmutation="1">faq</font>
                <span>よくある質問</span>
              </h2>
            </div>
            <div className="wrp-list">
              <ul>
                {faqs.map((faq, index) => (
                  <li
                    key={index}
                    className={`fadein isPlay`}
                    style={{
                      backgroundColor:
                        openIndex === index ? "#f9f9f8" : "transparent",
                    }}
                  >
                    <div
                      className={`wrp-tit tit_open ${
                        openIndex === index ? "down" : ""
                      }`}
                      onClick={() => toggleFAQ(index)}
                    >
                      <span className="icon">Q</span>
                      <span className="tit">{faq.question}</span>
                    </div>
                    <div
                      className="wrp-txt"
                      style={{
                        display: openIndex === index ? "block" : "none",
                      }}
                    >
                      <p className="txt">{faq.answer}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section className="block-voice">
          <div className="base">
            <div className="com-tit">
              <h2 className="tit">
                <font _mstmutation="1">voice</font>
                <span>お客様の声</span>
              </h2>
            </div>
            <ul className="block-ph-txt04" id="slide-sp">
              <li className="list-box">
                <div className="wrp-tit">
                  <div className="wrp-img">
                    <img
                      src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/voice-icon01.png"
                      alt=""
                    />
                  </div>
                  <div className="wrp-txt">
                    <h3 className="tit">
                      A様 30代女性　
                      <br className="sp" />
                      東京都
                    </h3>
                    <div className="rate">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/voice-rate.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="wrp-txt">
                  <p className="txt">
                    こちらの好みや要望を取り入れながらも、全体のバランスを考えたプロとしての意見を交えて提案してくださり、とても満足できるコーディネートをしていただけました。やりとりも迅速で、こちらの質問にも丁寧に答えてくださいました。
                    <br />
                    もうすぐ第一子が生まれるのですが、今後の子供との暮らしも視野に入れたお部屋にしてくださいました。今後自分でインテリアを選ぶ時にも役立ちそうなお話も聞くことができました。
                    <br />
                    人生で初めて理想のお部屋で暮らせそうです笑。
                    <br />
                    本当にありがとうございました！
                  </p>
                </div>
              </li>
              <li className="list-box">
                <div className="wrp-tit">
                  <div className="wrp-img">
                    <img
                      src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/voice-icon04.png"
                      alt=""
                    />
                  </div>
                  <div className="wrp-txt">
                    <h3 className="tit">
                      R様 30代男性　
                      <br className="sp" />
                      東京都
                    </h3>
                    <div className="rate">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/voice-rate.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="wrp-txt">
                  <p className="txt">
                    <font _mstmutation="1">
                      具体的な生活の事までよく考えていただき、デザイン性はもちろんのこと非常に住みやすそうなコーディネートをご提案いただきました。おそらく1人で家具を揃えていたら、デザインもイマイチで空調の行き届かない住みづらいレイアウトになっていたものと想像しています。
                      <br _mstmutation="1" />
                      また、説明が的確かつ分かりやすいため、ひとつひとつ納得しながら相談を進めることができましたし、提案書を拝見した際は「なるほどー…」と声が出ていました。
                      <br _mstmutation="1" />
                      連絡もレスポンス良く、結果として十分に納期より早く納品いただけて大満足です。
                      <br _mstmutation="1" />
                      ご提案ありがとうございました。インテリアコーディネート74様に依頼できて良かったと思っています。
                    </font>
                    <br />
                  </p>
                </div>
              </li>
              <li className="list-box">
                <div className="wrp-tit">
                  <div className="wrp-img">
                    <img
                      src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/voice-icon03.png"
                      alt=""
                    />
                  </div>
                  <div className="wrp-txt">
                    <h3 className="tit">
                      O様 20代女性　
                      <br className="sp" />
                      青森県
                    </h3>
                    <div className="rate">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/voice-rate.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="wrp-txt">
                  <p className="txt">
                    安い家具を使ってクィアアイみたいに部屋を大変身したいという無茶ぶりにも親身になってくださり、素敵なご提案もいただけて、大変満足しております。
                    <br />
                    何度もご質問を下さり、私の要望にあうよう丁寧に進めていただいてるのが伝わり安心しておまかせすることが出来ました。
                    <br />
                    「友人が泊まるときにも使える」や、ラグの敷き方等、細かい気配りに感激しております。
                    <br />
                    クロスも窓側にも入れるというのも、まさにプロの提案ですね･･！私にはそこまでの想像力がなく。
                    <br />
                    部屋の決め手になる家具や小物はセンスが良くて、オプションを付けて良かったです。
                    <br />
                    この度は、本当にありがとうござました。
                    <br />
                    またなにかあればお願いしたいです。
                  </p>
                </div>
              </li>
              <li className="list-box">
                <div className="wrp-tit">
                  <div className="wrp-img">
                    <img
                      src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/voice-icon06.png"
                      alt=""
                    />
                  </div>
                  <div className="wrp-txt">
                    <h3 className="tit">
                      K様 50代女性　
                      <br className="sp" />
                      東京都
                    </h3>
                    <div className="rate">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/voice-rate.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="wrp-txt">
                  <p className="txt">
                    新築物件の件で相談しました。担当のコーディネーターはおりましたが疑問に思う点もあり思い切ってお願いしました。
                    <br />
                    大変丁寧で気がつかないような的確なアドバイスをいただけたと思っております。いろいろアドバイスいただき有り難う御座います。
                    <br />
                    大切な時期にアドバイスいただけて良かったです。
                    <br />
                    深く御礼申し上げます。
                    <br />
                    これからも相談事が出来るかもしれません。
                    <br />
                    その時は改めてお願いしたいと存じます。
                    <br />
                    有り難うございました。
                  </p>
                </div>
              </li>
              <li className="list-box">
                <div className="wrp-tit">
                  <div className="wrp-img">
                    <img
                      src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/voice-icon05.png"
                      alt=""
                    />
                  </div>
                  <div className="wrp-txt">
                    <h3 className="tit">
                      G様 40代男性　
                      <br className="sp" />
                      神奈川県
                    </h3>
                    <div className="rate">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/voice-rate.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="wrp-txt">
                  <p className="txt">
                    リビングのレイアウトをお願いしました。
                    <br />
                    自分で選んでも思ったより大きかったり動線的に使いにくかったりして悩んでいました。
                    <br />
                    予定よりも早く対応できますとのことで、早くに丁寧なアドバイスを頂けました。早く居心地の悪いリビングから解放されたい！と思っていたのでありがたかったです。
                    <br />
                    資料を送ってすぐにわかりやすい3Dの画像を作って頂き、とても参考になりました。
                    <br />
                    テレビのコード類などを隠すには…などのアドバイスや、具体的な家具の提案などもしていただけたのでネットで実物を見ながらイメージ出来てとても良かったです。
                    <br />
                    実際の家具を入れるのが楽しみです。
                  </p>
                </div>
              </li>
              <li className="list-box">
                <div className="wrp-tit">
                  <div className="wrp-img">
                    <img
                      src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/voice-icon02.png"
                      alt=""
                    />
                  </div>
                  <div className="wrp-txt">
                    <h3 className="tit">
                      F様 30代女性　
                      <br className="sp" />
                      広島県
                    </h3>
                    <div className="rate">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/voice-rate.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="wrp-txt">
                  <p className="txt">
                    この度初めて依頼させて頂きました。
                    <br />
                    とても素敵なコーディネートにテンションが爆上がりしています。
                    <br />
                    素晴らしいです。本当にお願いしてよかったと思っています。
                    <br />
                    要望など細かく聞いてくれて、予想以上のコーディネートで大満足です!!お忙しいと思いますが、些細な質問に対しても丁寧に答えて下さり、感謝しています。また機会がありましたらぜひお願いしたいと思ってきます！！
                    <br />
                    ありがとうございました！
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section className="block-blog">
          <div className="base">
            <div className="com-tit">
              <h2 className="tit">
                <font _mstmutation="1">blog</font>
                <span>ブログ・実績</span>
              </h2>
            </div>
            <ul className="block-list-box04">
              {blogPosts.map((post, index) => (
                <li
                  className="list-box link-box"
                  key={index}
                  style={{ cursor: "pointer" }}
                >
                  <div className="wrp-img">
                    <img
                      width="1920"
                      height="1080"
                      src={post.imgSrc}
                      alt=""
                      decoding="async"
                      fetchpriority="high"
                    />
                  </div>
                  <div className="wrp-txt">
                    <div className="subtit">
                      <span className="one">article</span>
                      <span className="two"></span>
                      {post.subtit}
                    </div>
                    <h3>
                      <Link to="/" className="tit">
                        {post.title}
                      </Link>
                    </h3>
                    <p className="txt">{post.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section
          className="block-footer-cta"
          style={{ backgroundImage: backgroundImage }}
        >
          <div className="base">
            <div className="wrp-cta01">
              <div className="com-tit">
                <h2 className="tit">
                  <font _mstmutation="1">order</font>
                  <span>ご依頼</span>
                </h2>
              </div>
              <p className="txt">
                オンラインインテリアコーディネート74では、お客様
                <br />
                のご要望・スタイルに合わせて以下のご依頼方法を
                <br />
                ご用意しています。
              </p>
              <ul className="btns">
                <li className="line fadein fadein-bottom isPlay">
                  <Link to="/" className="link">
                    <div className="wrp-icon">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/icon-line-cta.png"
                        alt="LINE Brand Icon"
                      />
                    </div>
                    <div className="wrp-txt">
                      <span className="one">LINEでご依頼</span>
                      <span className="two">友達追加の上、依頼ください</span>
                    </div>
                  </Link>
                </li>
                <li className="mail fadein fadein-bottom isPlay">
                  <Link to="/" className="link">
                    <div className="wrp-icon">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/icon-envelope-cta.png"
                        alt="Form Icon"
                      />
                    </div>
                    <div className="wrp-txt">
                      <span className="one">フォームでのご依頼</span>
                      <span className="two">ご依頼フォームへ</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="block-prof">
          <div className="base">
            <p className="catch">
              オンラインインテリアコーディネート74はご自宅にいながらLINEやメールでインテリアコーディネートを依頼できるサービスです。
              <br />
              コーディネートでご提案する家具はイケア、ニトリ、楽天、Amazonなど国内全てのウェブサイトのアイテムが対象となります。
            </p>
            <dl className="block-table03">
              <div className="wrp-img">
                <img
                  src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/prof.png"
                  alt=""
                />
                <span className="name">松井　コウスケ</span>
              </div>
              <div className="wrp-txt">
                <dt>Profile</dt>
                <dd>
                  <p>
                    ローコストインテリアデザイナー。一定品質以上の低価格アイテムを使ったコーディネートを得意とする。
                    <br />
                    日本有数の感度の高いインテリアショップ、設計事務所で働いたのち独立。
                    <br />
                    国内最大手のスキルマーケットにてインテリア・家具部門ランキング1位多数、お客様総合評価5.0(満点)
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
