import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import "../css/main.css";
import "../css/mainFirst.css";
import "../css/contactus.css";
import { Link } from "react-router-dom";

function Contact() {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [details, setDetails] = useState("");
  const backgroundImage =
    "url(https://interiorcoordinate74.jp/wp-content/uploads/2023/09/リビング　照明-1.jpg)";

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    if (scrollY > 385) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const contactOrder = (event) => {
    event.preventDefault(); // 阻止默认表单提交行为
    const newErrors = {};

    // 获取所有必填项
    const requiredFields = document.querySelectorAll(
      "dt:has(span.asterisk) + dd input, dt:has(span.asterisk) + dd textarea"
    );

    requiredFields.forEach((input) => {
      if (!input.value) {
        newErrors[input.name] = "この項目は必須入力です";
        input.style.border = "2px solid red";
      } else {
        input.style.border = "";
      }
    });

    const emailInput = document.querySelector('input[name="email-contact"]');
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(emailInput.value)) {
      newErrors["email-contact"] = "正しいメールアドレスを入力してください";
      emailInput.style.border = "2px solid red";
    } else {
      emailInput.style.border = "";
    }

    if (Object.keys(newErrors).length > 0) {
      alert("すべての必須項目を正しく記入してください!");
    } else {
      alert("送信成功!");
      setName("");
      setCompany("");
      setEmail("");
      setTel("");
      setDetails("");
    }
  };

  return (
    <div className="main-container">
      <div className={`header-container ${scrolled ? "scrolled" : ""}`}>
        <div className="header">
          <div className="header__inner">
            <div className="wrp-navi">
              <Link to="/" className="btn isActive" onClick={toTop}>
                HOME
                <FontAwesomeIcon icon={faHouse} />
              </Link>
              <div className="wrp-trigger02">
                <div className="menu-trigger02" href="/">
                  <span></span> <span></span> <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mainBox">
        <div className="transparent-div1" style={{ height: "385px" }}>
          <img alt="" src="/head/contact.jpeg" />
          <div className="wrp-txt1">
            <div className="wrp-txt">
              <h2 class="tit">
                Contact<span>お問い合わせ</span>
              </h2>
            </div>
          </div>
        </div>

        {/* 联系信息 */}
        <section className="wrp-form">
          <div className="base-sub">
            <span className="p-country-name" style={{ display: "none" }}>
              Japan
            </span>
            <div className="head-form01">
              <img
                src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/icon-email.png"
                alt=""
                className="icon"
              />
              <h2 className="tit">フォームからお問い合わせ</h2>
              <p className="txt">
                以下をご記入いただき送信ボタンを押してください。
                <br />
                3日以内にご記入いただいたメールアドレスにご回答させていただきます。
              </p>
            </div>
            <div className="note">
              <span className="asterisk">★</span>入力必須
            </div>

            <div className="wp-block-contact-form-7-contact-form-selector">
              <div className="wpcf7 js" id="wpcf7-f19-o1" lang="ja" dir="ltr">
                <form
                  className="wpcf7-form init"
                  aria-label="コンタクトフォーム"
                  noValidate
                  onSubmit={contactOrder}
                >
                  <div className="form-area01">
                    <dl>
                      <div className="wrp-list">
                        <dt>
                          <p>
                            お名前<span className="asterisk">★</span>
                          </p>
                        </dt>
                        <dd>
                          <div className="input-style">
                            <p>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="text-name"
                              >
                                <input
                                  size="40"
                                  maxLength="400"
                                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="山田　太郎"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  type="text"
                                  name="text-name"
                                  required
                                />
                              </span>
                            </p>
                          </div>
                        </dd>
                      </div>
                      <div className="wrp-list">
                        <dt>
                          <p>会社名(法人の場合)</p>
                        </dt>
                        <dd>
                          <div className="input-style">
                            <p>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="text-corp"
                              >
                                <input
                                  size="40"
                                  maxLength="400"
                                  className="wpcf7-form-control wpcf7-text"
                                  aria-invalid="false"
                                  placeholder="◯◯ 株式会社"
                                  value={company}
                                  onChange={(e) => setCompany(e.target.value)}
                                  type="text"
                                  name="text-corp"
                                />
                              </span>
                            </p>
                          </div>
                        </dd>
                      </div>
                      <div className="wrp-list">
                        <dt>
                          <p>
                            メールアドレス<span className="asterisk">★</span>
                          </p>
                        </dt>
                        <dd>
                          <div className="input-style">
                            <p>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="email-contact"
                              >
                                <input
                                  size="40"
                                  maxLength="400"
                                  className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="sell@etahcd.xyz"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  type="email"
                                  required
                                  name="email-contact"
                                />
                              </span>
                            </p>
                          </div>
                        </dd>
                      </div>
                      <div className="wrp-list">
                        <dt>
                          <p>お電話番号</p>
                        </dt>
                        <dd>
                          <div className="input-style">
                            <p>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="tel-contact"
                              >
                                <input
                                  size="40"
                                  maxLength="400"
                                  className="wpcf7-form-control wpcf7-tel wpcf7-text wpcf7-validates-as-tel"
                                  aria-invalid="false"
                                  placeholder="(415) 425-9345 ※ハイフンなし"
                                  value={tel}
                                  onChange={(e) => setTel(e.target.value)}
                                  type="tel"
                                  name="tel-contact"
                                />
                              </span>
                            </p>
                          </div>
                        </dd>
                      </div>
                      <div className="wrp-list">
                        <dt>
                          <p>
                            お問い合わせ内容<span className="asterisk">★</span>
                          </p>
                        </dt>
                        <dd>
                          <div className="textarea-style">
                            <p>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="textarea-contact"
                              >
                                <textarea
                                  cols="40"
                                  rows="10"
                                  maxLength="2000"
                                  className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="お問い合わせ内容をご記載ください"
                                  name="textarea-contact"
                                  value={details}
                                  onChange={(e) => setDetails(e.target.value)}
                                  required
                                />
                              </span>
                            </p>
                          </div>
                        </dd>
                      </div>
                    </dl>
                    <div className="wrp-chek">
                      <p className="personal-info">
                        ご記入いただいた個人情報は、お問い合わせへのご返信及び、連絡のみに利用させていただきます。
                      </p>
                    </div>
                    <p>
                      <input
                        className="wpcf7-form-control wpcf7-submit has-spinner"
                        type="submit"
                        value="送　信"
                        onClick={contactOrder}
                      />
                      <span className="wpcf7-spinner"></span>
                    </p>
                  </div>
                  <div
                    className="wpcf7-response-output"
                    aria-hidden="true"
                  ></div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="other-box">
          <div className="base">
            <div className="wrp-inner">
              <div className="inner">
                <img
                  src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/icon-line.png"
                  alt=""
                  className="icon"
                />
                <h3 className="tit">LINEでのお問い合わせ</h3>
                <p className="txt">
                  スマートフォンでコーディネートを受けたいお客様に特におすすめ。
                  <br />
                  <Link to="/">友達追加</Link>
                  の上、お問い合わせ内容を送信ください。
                </p>
              </div>
              <div className="inner">
                <img
                  src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/icon-sp.png"
                  alt=""
                  className="icon"
                />
                <h3 className="tit">お電話でのお問い合わせ</h3>
                <p className="txt">
                  24時間365日対応可能ですが
                  <Link to="tel:(415) 425-9345">お電話</Link>
                  をいただいたタイミングに
                  <br />
                  よっては出られないケースもございます。
                  <br />
                  その場合は「(415) 425-9345」より折り返しさせて頂きます。
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          className="block-footer-cta"
          style={{ backgroundImage: backgroundImage, margin: 0 }}
        >
          <div className="base">
            <div className="wrp-cta01">
              <div className="com-tit">
                <h2 className="tit">
                  <font _mstmutation="1">order</font>
                  <span>ご依頼</span>
                </h2>
              </div>
              <p className="txt">
                オンラインインテリアコーディネート74では、お客様
                <br />
                のご要望・スタイルに合わせて以下のご依頼方法を
                <br />
                ご用意しています。
              </p>
              <ul className="btns">
                <li className="line fadein fadein-bottom isPlay">
                  <Link to="/" className="link">
                    <div className="wrp-icon">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/icon-line-cta.png"
                        alt="LINE Brand Icon"
                      />
                    </div>
                    <div className="wrp-txt">
                      <span className="one">LINEでご依頼</span>
                      <span className="two">友達追加の上、依頼ください</span>
                    </div>
                  </Link>
                </li>
                <li className="mail fadein fadein-bottom isPlay">
                  <Link to="/" className="link">
                    <div className="wrp-icon">
                      <img
                        src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/icon-envelope-cta.png"
                        alt="Form Icon"
                      />
                    </div>
                    <div className="wrp-txt">
                      <span className="one">フォームでのご依頼</span>
                      <span className="two">ご依頼フォームへ</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="block-prof">
          <div className="base">
            <p className="catch">
              オンラインインテリアコーディネート74はご自宅にいながらLINEやメールでインテリアコーディネートを依頼できるサービスです。
              <br />
              コーディネートでご提案する家具はイケア、ニトリ、楽天、Amazonなど国内全てのウェブサイトのアイテムが対象となります。
            </p>
            <dl className="block-table03">
              <div className="wrp-img">
                <img
                  src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/prof.png"
                  alt=""
                />
                <span className="name">松井　コウスケ</span>
              </div>
              <div className="wrp-txt">
                <dt>Profile</dt>
                <dd>
                  <p>
                    ローコストインテリアデザイナー。一定品質以上の低価格アイテムを使ったコーディネートを得意とする。
                    <br />
                    日本有数の感度の高いインテリアショップ、設計事務所で働いたのち独立。
                    <br />
                    国内最大手のスキルマーケットにてインテリア・家具部門ランキング1位多数、お客様総合評価5.0(満点)
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
