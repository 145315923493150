import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Particular() {
  const backgroundImage =
    "url(https://interiorcoordinate74.jp/wp-content/uploads/2023/09/リビング　照明-1.jpg)";

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <div className="header-container">
        <div className="header">
          <div className="header__inner">
            <div className="wrp-navi">
              <Link
                to="/"
                className="btn isActive"
                onClick={toTop}
                style={{ color: "#000", border: "1px solid #000" }}
              >
                HOME
                <FontAwesomeIcon icon={faHouse} style={{ color: "#000" }} />
              </Link>
              <div className="wrp-trigger02">
                <div className="menu-trigger02" href="/">
                  <span style={{ backgroundColor: "#000" }}></span>
                  <span style={{ backgroundColor: "#000" }}></span>
                  <span style={{ backgroundColor: "#000" }}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mainBox" style={{ marginTop: "91px" }}>
        <div className="mainBox1" style={{ padding: "0 28%" }}>
          <div className="container-fluid">
            <div className="signpost1">
              <h1 style={{ textAlign: "center", fontSize: "21px" }}>
                特定商取引法の表記
              </h1>
              <p style={{ fontWeight: "700" }}>
                （インテリアコーディネートサービス）
              </p>
              <br />
              <p>
                <strong>販売価格</strong>
              </p>
              <p>
                販売価格は、個別に提⽰します。販売価格の表⽰は、消費税込の総額表⽰です。
                販売価格はサイトトップページにも記載がございます。
              </p>
              <p>
                <strong>代⾦のお⽀払い⽅法</strong>
              </p>
              <p>クレジットカードによるお支払い、銀⾏振込によるお⽀払い</p>
              <p>
                <strong>代⾦のお⽀払い時期</strong>
              </p>
              <p>
                ・クレジットカード決済ご利⽤のカード会社の締め⽇や契約内容により異なります。ご利⽤のカード会社までご確認ください。
                <br />
                ・銀⾏振込当方よりお⽀払い情報案内後、14⽇以内にお⽀払ください。
              </p>
              <p>
                <strong>役務または商品の引渡し時期</strong>
              </p>
              <p>
                本サービスは役務提供前に代⾦をご請求するサービスとなっております。
                <br />
                請求金額お支払い後ただちに役務を提供致します。
              </p>
              <p>
                <strong>返品についての特約に関する事項</strong>
              </p>
              <p>
                本サービスは役務提供前に代⾦をご請求しお支払い後ただちに役務を提供するサービスとなっております。お支払い後のキャンセル及び返金はできません。
              </p>
              <p>
                <strong>商品代⾦以外に必要な料⾦</strong>
              </p>
              <p>銀⾏振込時の振込⼿数料はお客様にご負担いただきます。</p>
              <br />
              <p style={{ fontWeight: "700" }}>
                （インテリアコーディネートサービス、商品販売）
              </p>
              <br />
              <p>
                <strong>個⼈情報の守秘義務</strong>
              </p>
              <p>
                当方は、お客様の個⼈情報の取扱いについて、個⼈情報の取扱いに関する法令、国が定める指針、その他の規範を遵守し、適正な取扱いと安全管理に努めて参ります。詳しくはプライバシーポリシーをご覧ください。
              </p>
              <p>
                <strong>事業者（販売業者）・連絡先</strong>
              </p>
              <p>
                事業者：オンキャリア　
                <br />
                販売責任者：松井　コウスケ
                <br />
                所在地：1309 Coffeen Avenue STE 1200，Sheridan, Wyoming 82801
              </p>
              <p>
                電話番号：(415) 425-9345
                <br />
                メールアドレス：sell@etahcd.xyz
              </p>
              <br />
              <p style={{ fontWeight: "700" }}>（商品販売）</p>
              <p>
                商品販売は個別の希望、希望内容によってご対応させていただきます。
              </p>
              <br />
              <p>
                <strong>販売価格</strong>
              </p>
              <p>
                商品ごとに表⽰致します。ただしオーダー品につきましてはオーダー内容ごとに価格が異
                なりますので、お⾒積りの際に詳細を明⽰致します。
              </p>
              <p>
                <strong>代⾦のお⽀払い⽅法</strong>
              </p>
              <p>
                ・クレジットカード決済
                ご利⽤のカード会社の締め⽇や契約内容により異なります。ご利⽤のカード会社までご確認
                ください。
                <br />
                ・銀⾏振込
                当方よりお⽀払い情報案内後、14⽇以内にお⽀払ください。
              </p>
              <p>
                <strong>商品の引渡し時期</strong>
              </p>
              <p>
                ご注⽂代⾦の⼊⾦確認後、30⽇以内に発送致します。発送に時間がかかる場合や受注⽣産品の場合には、納期をご連絡致します。
              </p>
              <p>
                <strong>返品についての特約に関する事項</strong>
              </p>
              <p>
                決済完了後のキャンセル・内容変更(追加・変更)は⼀切お受け致しかねます。ご注⽂完了前
                にご注⽂内容を必ずご確認ください。
                受注⽣産品・海外取り寄せ品は、決済完了後のキャンセル・内容変更(追加・変更)は⼀切お
                受け致しかねます。
                <br />
                ご注⽂と異なる商品が届いた場合は商品到着後5⽇以内にご連絡ください。状況確認後、同⼀商品との
                交換または修理により対応致します。同⼀商品がご⽤意できる場合、部品交換または修理に
                より改善可能な不具合の場合は返⾦によるご対応は致しかねますのであらかじめご了承くだ
                さい。
                なお、お客様のご都合によるキャンセル・返品は⼀切承っておりません。あらかじめご了承
                の上ご注⽂くださいますようお願い致します。
                <br />
                ご注⽂頂いた商品の取り消し、変更、⾊やサ
                イズ等の交換は致しかねますので、決済前に必ずご注⽂内容のご確認をお願い致します。
                商品写真はお客様のPCのモニター(液晶の差や輝度の違いなど)や画⾯設定、商品写真の撮影状況など
                により、多少の⾊味の違いが発⽣する場合がございます。
                また、⽊⽬・年輪などの出⽅、ファブリック・レザーなどの⽣地の柄・模様の出⽅、古材を
                使った商品の仕上げ、ビンテージ加⼯商品の仕上げなど、特性によりひとつひとつが異なる
                表情を持つ商品もございます。
                ⾊、質感などがイメージとは違うといった理由による返品、交換、変更など、決済完了後の
                キャンセルは⼀切お受け致しかねますので、あらかじめご了承くださいますようお願い致し
                ます。
              </p>
              <p>
                <strong>商品代⾦以外に必要な料⾦</strong>
              </p>
              <p>送料及び商品手配手数料は個別でのお⾒積もりになります。</p>
            </div>
          </div>
        </div>
      </div>

      <section
        className="block-footer-cta"
        style={{ backgroundImage: backgroundImage, margin: 0 }}
      >
        <div className="base">
          <div className="wrp-cta01">
            <div className="com-tit">
              <h2 className="tit">
                <font _mstmutation="1">order</font>
                <span>ご依頼</span>
              </h2>
            </div>
            <p className="txt">
              オンラインインテリアコーディネート74では、お客様
              <br />
              のご要望・スタイルに合わせて以下のご依頼方法を
              <br />
              ご用意しています。
            </p>
            <ul className="btns">
              <li className="line fadein fadein-bottom isPlay">
                <Link to="/" className="link">
                  <div className="wrp-icon">
                    <img
                      src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/icon-line-cta.png"
                      alt="LINE Brand Icon"
                    />
                  </div>
                  <div className="wrp-txt">
                    <span className="one">LINEでご依頼</span>
                    <span className="two">友達追加の上、依頼ください</span>
                  </div>
                </Link>
              </li>
              <li className="mail fadein fadein-bottom isPlay">
                <Link to="/" className="link">
                  <div className="wrp-icon">
                    <img
                      src="https://interiorcoordinate74.jp/wp-content/themes/interior74/images/icon-envelope-cta.png"
                      alt="Form Icon"
                    />
                  </div>
                  <div className="wrp-txt">
                    <span className="one">フォームでのご依頼</span>
                    <span className="two">ご依頼フォームへ</span>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="block-prof">
        <div className="base">
          <p className="catch">
            オンラインインテリアコーディネート74はご自宅にいながらLINEやメールでインテリアコーディネートを依頼できるサービスです。
            <br />
            コーディネートでご提案する家具はイケア、ニトリ、楽天、Amazonなど国内全てのウェブサイトのアイテムが対象となります。
          </p>
          <dl className="block-table03">
            <div className="wrp-img">
              <img
                src="https://interiorcoordinate74.jp/wp-content/uploads/2022/07/prof.png"
                alt=""
              />
              <span className="name">松井　コウスケ</span>
            </div>
            <div className="wrp-txt">
              <dt>Profile</dt>
              <dd>
                <p>
                  ローコストインテリアデザイナー。一定品質以上の低価格アイテムを使ったコーディネートを得意とする。
                  <br />
                  日本有数の感度の高いインテリアショップ、設計事務所で働いたのち独立。
                  <br />
                  国内最大手のスキルマーケットにてインテリア・家具部門ランキング1位多数、お客様総合評価5.0(満点)
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Particular;
